import { useTranslation } from 'react-i18next'
import React, { FC } from 'react'
import { Controller } from 'react-hook-form'
import { Button } from '~/core/ui/Button'
import { DynamicImportForm } from '~/core/ui/DynamicImportForm'
import { FormControlItem } from '~/core/ui/FormControlItem'
import { Input } from '~/core/ui/Input'
import { schemaUpdateEmailProfile } from '~/lib/features/candidates/schema/validation-create-profile'

const InputEmailCandidateControl: FC<{
  autoFocus?: boolean
  onSubmitUpdateEmailProfile: (params: { email: string }) => Promise<void>
  isLoadingUpdateEmailProfile: boolean
  isShowFullAction?: boolean
}> = ({
  autoFocus,
  onSubmitUpdateEmailProfile,
  isLoadingUpdateEmailProfile,
  isShowFullAction
}) => {
  const { t } = useTranslation()

  return (
    <DynamicImportForm
      id="update-email-profile-form"
      className="w-full"
      schema={schemaUpdateEmailProfile(t)}
      onSubmit={onSubmitUpdateEmailProfile}>
      {({ formState, control, submit }) => {
        return (
          <div className="flex w-full justify-center bg-gray-50">
            <div className="flex flex-col py-6">
              <span className="text-center text-base font-medium text-gray-900">
                {t('candidates:tabs:candidateEmail:candidateEmailRequired')}
              </span>
              <span className="text-left text-sm text-gray-700">
                {t('candidates:tabs:candidateEmail:candidateEmailProceed')}
              </span>
              <div className="mt-3 flex flex-row">
                <Controller
                  control={control}
                  name="email"
                  render={({ field: { onChange, value } }) => {
                    return (
                      <div className="mr-3 flex flex-1 flex-col">
                        <FormControlItem
                          destructive={
                            formState.errors && !!formState.errors.email
                          }
                          destructiveText={
                            formState.errors &&
                            (formState.errors.email?.message as string)
                          }>
                          <Input
                            autoFocus
                            placeholder={`${t(
                              'candidates:placeholder:inputCandidateEmail'
                            )}`}
                            className="min-w-0"
                            size="sm"
                            onChange={onChange}
                            value={value}
                            destructive={
                              formState.errors && !!formState.errors.email
                            }
                          />
                        </FormControlItem>
                      </div>
                    )
                  }}
                />
                <Button
                  htmlType="submit"
                  onClick={submit}
                  isDisabled={isLoadingUpdateEmailProfile || isShowFullAction}
                  isLoading={isLoadingUpdateEmailProfile}
                  size="sm"
                  type="primary"
                  label={`${t('button:save')}`}
                />
              </div>
            </div>
          </div>
        )
      }}
    </DynamicImportForm>
  )
}

export default InputEmailCandidateControl

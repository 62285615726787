import { FC, useCallback } from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { AGENCY_TENANT } from '~/core/constants/enum'
import { Button } from '~/core/ui/Button'
import { Dialog } from '~/core/ui/Dialog'
import { DynamicImportForm } from '~/core/ui/DynamicImportForm'
import { FormControlItem } from '~/core/ui/FormControlItem'
import { Input } from '~/core/ui/Input'
import schemaAddEmailForm from '~/lib/features/calendar/schema/validation-add-email-form'
import MutationAgencyProfileUpdate from '~/lib/features/candidates/graphql/mutation-agency-update-profile'
import MutationProfileUpdate from '~/lib/features/candidates/graphql/mutation-update-profile'
import useDetectCompanyWithKind from '~/lib/hooks/use-detect-company-with-kind'
import { useSubmitCommon } from '~/lib/hooks/use-submit-graphql-common'

const AddEmailModal: FC<{
  open: boolean
  onClose: () => void
  candidateProfileId: number
  callbackSubmit?: (data: { email: string | string[] }) => void
  callbackCancel?: () => void
  reload?: () => Promise<void>
}> = ({
  open,
  onClose,
  callbackCancel,
  candidateProfileId,
  callbackSubmit,
  reload
}) => {
  const { t } = useTranslation()
  const { isCompanyKind } = useDetectCompanyWithKind({ kind: AGENCY_TENANT })
  const { trigger: triggerUpdateProfile, isLoading: isLoadingUpdateProfile } =
    useSubmitCommon(
      isCompanyKind ? MutationAgencyProfileUpdate : MutationProfileUpdate
    )

  const onFinish = useCallback(
    (data: { email: string }) => {
      return triggerUpdateProfile({
        id: candidateProfileId,
        email: [data.email]
      }).then(() => {
        callbackSubmit && callbackSubmit({ email: [data.email] })
        reload &&
          reload().then(() => {
            onClose()
          })
      })
    },
    [candidateProfileId, reload]
  )

  const onCloseModal = useCallback(() => {
    onClose()
    callbackCancel && callbackCancel()
  }, [])
  return (
    <Dialog
      open={open}
      size="sm"
      onOpenChange={onCloseModal}
      isDivider={false}
      isPreventAutoFocusDialog={true}
      label={`${t('interview:email_modal:title')}`}
      description={`${t('interview:email_modal:description')}`}
      headingClassName="tablet:pb-5">
      <DynamicImportForm
        className="w-full"
        schema={schemaAddEmailForm(t)}
        defaultValue={{
          email: ''
        }}
        onSubmit={onFinish}>
        {({ formState, control }) => {
          return (
            <>
              <div className="mb-6">
                <Controller
                  control={control}
                  name="email"
                  defaultValue=""
                  render={({ field: { onChange, value } }) => (
                    <FormControlItem
                      destructive={
                        formState.errors && !!formState.errors?.email
                      }
                      destructiveText={
                        formState.errors &&
                        (formState.errors?.email?.message as string)
                      }>
                      <Input
                        placeholder={`${t(
                          'interview:email_modal:email_placeholder'
                        )}`}
                        size="sm"
                        onChange={onChange}
                        value={value}
                        destructive={
                          formState.errors && !!formState.errors?.email
                        }
                      />
                    </FormControlItem>
                  )}
                />
              </div>
              <div className="flex items-center justify-end space-x-3">
                <Button
                  label={`${t('button:cancel')}`}
                  isLoading={isLoadingUpdateProfile}
                  isDisabled={isLoadingUpdateProfile}
                  size="sm"
                  type="secondary"
                  onClick={onCloseModal}
                />
                <Button
                  size="sm"
                  isLoading={isLoadingUpdateProfile}
                  isDisabled={isLoadingUpdateProfile}
                  label={`${t('interview:email_modal:save')}`}
                  htmlType="submit"
                />
              </div>
            </>
          )
        }}
      </DynamicImportForm>
    </Dialog>
  )
}

export default AddEmailModal
